import React, { FC, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { animateScroll } from 'react-scroll';
import * as styles from './ScrollToTop.module.styl';

interface State {
  isVisible: boolean
}

/**
 * ページトップにスクロールするボタン
 */
const ScrollToTop: FC = () => {
  const [state, setState] = useState<State>({
    isVisible: false,
  });

  const {
    isVisible,
  } = state;

  const scrollToTop = (): void => animateScroll.scrollToTop({ duration: 300 });

  useEffect(() => {
    const onScroll = (): void => {
      const OFFSET = 500;
      const y = window.pageYOffset;
      setState({
        ...state,
        isVisible: y > OFFSET,
      });
    };
    window.addEventListener('scroll', onScroll);
    return function cleanup(): void {
      window.removeEventListener('scroll', onScroll);
    };
  }, [setState]);

  return (
    <Link
      onClick={scrollToTop}
      to="#top"
      className={`is-block has-background-white ${styles.pagetop} ${
        isVisible ? styles.pagetopShow : ''
      }`}
    />
  );
};

export default ScrollToTop;
