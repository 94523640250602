// extracted by mini-css-extract-plugin
export const sectionArea = "index-module--section-area--10Cn2";
export const innerContainer = "index-module--inner-container--2qoQN";
export const sectionBgMain = "index-module--section-bg-main--2qdga";
export const whiteSpaceNowrap = "index-module--white-space-nowrap--GnQbK";
export const pagetop = "index-module--pagetop--6F2X2";
export const pagetopShow = "index-module--pagetop-show--3LAfc";
export const contentBody = "index-module--content-body--1uqg6";
export const contentBodyList = "index-module--content-body-list--2iTWc";
export const mainHeading = "index-module--main-heading--3wktW";
export const headingBold = "index-module--heading-bold--1l5xU";
export const fap_contact = "index-module--fap_contact--2O1_1";
export const contentText = "index-module--content-text--9i4Pd";
export const gridArea = "index-module--grid-area--3ELfh";
export const fapContactlist = "index-module--fap-contactlist--2S5Yo";
export const line = "index-module--line--3MKoe";
export const btn = "index-module--btn--Yq7p7";
export const iconLine = "index-module--icon-line--ql2Vy";
export const cLogoCarmo = "index-module--c-logo--carmo--2So8F";
export const cLogoCarmoWhite = "index-module--c-logo--carmo-white--3kQHD";
export const cLogoLine = "index-module--c-logo--line--37KiT";
export const list = "index-module--list--1R051";
export const listText = "index-module--list-text--19VuY";
export const listButton = "index-module--list-button--23ZS9";
export const listButtonNavigation = "index-module--list-button-navigation--Ad3SU";
export const listButtonNews = "index-module--list-button-news--1dXem";
export const icoArrow = "index-module--ico-arrow--2uQ7_";
export const icoArrowBack = "index-module--ico-arrow-back--3quNk";
export const iconArrowDown = "index-module--icon-arrow-down--2whq6";
export const listArrow = "index-module--list-arrow--1Q74_";
export const listArrowDown = "index-module--list-arrow-down--2Dyqa";
export const listArrowLeft = "index-module--list-arrow-left--GEMfD";
export const faqHeading = "index-module--faq-heading--2jDk1";
export const faqContainer = "index-module--faq-container--1eLyF";
export const isCategory = "index-module--is-category--VaotM";
export const faqList = "index-module--faq-list--IIyb1";
export const faqContactContainer = "index-module--faq-contact-container--1Moe8";
export const faqContactCard = "index-module--faq-contact-card--E_EiO";
export const faqContactLinkTel = "index-module--faq-contact-link-tel--3ewXI";
export const faqContactText = "index-module--faq-contact-text--2SXHf";
export const faqContactTextMin = "index-module--faq-contact-text-min--1KofI";
export const faqCategoryLink = "index-module--faq-category-link--3CaWn";
export const faqContainerHelper = "index-module--faq-container-helper--U6wJ0";
export const faqCharmContainerHelper = "index-module--faq-charm-container-helper--2Uy6m";
export const faqFootContainer = "index-module--faq-foot-container--3lF4O";
export const faqFootContainerBody = "index-module--faq-foot-container-body--3W-Nm";
export const faqFootBannerContainer = "index-module--faq-foot-banner-container--3s6-d";
export const hasUnderline = "index-module--has-underline--uWIwq";
export const keywordInputContainer = "index-module--keyword-input-container--2jMFx";
export const keywordInputField = "index-module--keyword-input-field--2vEt_";
export const keywordInputButton = "index-module--keyword-input-button--3UZmU";
export const keywordTipContainer = "index-module--keyword-tip-container--314xi";
export const keywordTipItem = "index-module--keyword-tip-item--3SX4h";